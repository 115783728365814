import React from 'react';
import './PortfolioColumn.css';
import { useParams, useNavigate } from 'react-router-dom';
import PortfolioImages from './PortfolioImages';



const PortfolioColumn = ({datasource}) => {
    console.log(datasource)

    const {id} = useParams();
    const navigate = useNavigate();



  return (
    <div className='portfolio-column-container' >

        {datasource
        .filter(data =>  data.id  == id)
         .map((data)=>(
      
          <div key={data.id} className='port-inner-container'>

             <div className='port-top-container'>

            
                 {data.images.map((images, index)=>(
                  <PortfolioImages key={index} images={images} />
                  
            ))}
             </div>
              
            <div className='port-inner-bottom'>
                  <h3>{data.title}</h3>
                    <h6>Built with: {data.builtWith}</h6>
                  <div className='links'>
                  <a href={data.gitHub} target="_blank" rel="noopener noreferrer">Repository</a>
                 {data.websiteLink && <a href={data.websiteLink}   target="_blank" rel="noopener noreferrer" >Visit Page</a>}
                </div> 
          </div>
          </div>
         ) )

        }
         <button onClick={() => navigate(-1)}>Go back</button>

    </div>
  )
}

export default PortfolioColumn
