import './App.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import PortfolioColumn from './components/PortfolioColumn';
// import Footer from './components/Footer';
import datasource from './assets/datasource';


function App() {

 

 

  return (
    <div
     className="App">
       <BrowserRouter>
      <Navbar />
      <Routes>
      <Route path='/' exact element={<Home/>} />
      <Route path='/portfolio'  element={<Portfolio />} />
      <Route path='/portfolio/:id'  element={<PortfolioColumn datasource={datasource} />} /> 
      <Route path='/about'  element={<About />} />
      <Route path='/contact' exact  element={<Contact />}  />
    
    
   


      </Routes>
      {/* <Footer /> */}
      {/* <div>
        <p>Designed and Developed by Precious</p>
      </div>
       */}
      </BrowserRouter>
    </div>
  );
}

export default App;
