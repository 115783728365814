import React from 'react'
import About from './About'
import Contact from './Contact'
import HeroContainer from './Herocontainer'
import './Home.css'
import Portfolio from './Portfolio'
import Featured from '../components/Featured'

const Home = () => {
  return (
    <div className='home-container'>
      <HeroContainer />

      <Featured />

     {/* <Portfolio /> */}
     <div className='home-about-container'>

     <About />

     </div>
    
     <div className='contact-home'>
     <Contact />
     </div>
    
      
    </div>
  )
}

export default Home

