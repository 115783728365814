import React from 'react';
import './featured.css';
import featuredDatasource from '../assets/featuredDatasource';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Featured = () => {
  return (
    <div className='featuredContainer' >
        <h1 style={{color:'white', }}>Recent Projects</h1>

        <div className='featuredInnerContainer'>

        <div className='eachFeatured'>
       
       <a href='https://hybridtechrenewableenergy.com' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Renewable Energy Company for HybridTech - hybridtechrenewableenergy.com</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/hybridTech.png' alt='website' />
        
      

         </div>
       
         <p>Built with: React</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>

        <div className='eachFeatured'>
       
       <a href='https://timely.switchfusiontech.com' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Simple Task Manager web app for daily use - timely.switchfusiontech.com</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/switchTask.png' alt='website' />
        
      

         </div>
       
         <p>Built with: React & Java</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>

        <div className='eachFeatured'>
       
       <a href='https://ugommaugorji.com' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A responsive E-commerce store for Ugo clothing brand - ugommaugorji.com</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/ugomma.png' alt='website' />
        
      

         </div>
       
         <p>Built with: React, Nodejs</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>

        <div className='eachFeatured'>
       
       <a href='https://lunnikelempire.com' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A responsive Real Estate & Haulage Company - lunnikelempire.com</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/lunnikel.png' alt='website' />
        
      

         </div>
       
         <p>Built with: React</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>

      

        <div className='eachFeatured'>
       
       <a href='https://switchfusiontech.com' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive website for mobile app and website development company - switchfusiontech.com</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/switcha.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: React</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>

        <div className='eachFeatured'>
       
       <a href='https://preciousphotographs.com' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Photography Website - preciousphotographs.com</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/12a.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: HTML, Javascript, CSS</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>
        <div className='eachFeatured'>
       
       <a href='https://precious-random-dad-jokes.netlify.app/' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Random Dad Joke App</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/15a.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: HTML, Javascript, CSS</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>
        <div className='eachFeatured'>
       
       <a href='https://the-pwhub-first-javascript-dashboard.netlify.app/' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Admin Dashboard</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/21a.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: HTML, Javascript, CSS</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>
        <div className='eachFeatured'>
       
       <a href='https://precious-bill-splitter-app.netlify.app/' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Bill Splitter App challenge from frontendmentors.io</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/25a.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: HTML, Javascript, CSS</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>
        <div className='eachFeatured'>
       
       <a href='https://precious-vacation-booking-app.netlify.app/' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Vacation Booking Website</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/44a.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: React</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>
        <div className='eachFeatured'>
       
       <a href='https://admin-dashboard-with-syncfusion.netlify.app/' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Dashboard With Various Charts And Theme Switcher</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/34b.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: React, Syncfusion</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>
        <div style={{height:'420px'}} className='eachFeatured'>
       
       <a href='https://play.google.com/store/apps/details?id=com.userappice' target='_blank'>

       <AnimationOnScroll animateIn="animate__bounceIn">
       <div className='featured-item'>
         <h3>A Responsive Mobile app for delivery for both drivers and users, out on both IOS and Android</h3>

         <div className='featuredImageContainer'>
         <img src='../img/portfolio/iceriders.png' alt='website image' />
        
      

         </div>
       
         <p>Built with: React Native, Tailwind, CSS</p>
         
       </div>
       </AnimationOnScroll>

       </a>

        </div>

        </div>
      
    </div>
  )
}

export default Featured
