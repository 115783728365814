import React from 'react';
import './PortfolioImages.css';


const PortfolioImages = ({images}) => {
  return (
    <div className='image-container'
    style={{
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
     
      backgroundColor: '#ca8536',

      }} >
               <img style={{width:'350px', objectFit: 'contain', margin: '30px' }} src={images} alt='project-images' />
    </div>
  )
}

export default PortfolioImages
