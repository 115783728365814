import React from 'react'
import './Portfolio.css'
import datasource from '../assets/datasource';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const Portfolio = () => {
   const {ref, inView} = useInView();
   const animation = useAnimation();

   useEffect(()=>{
    if(inView){
     animation.start({
       x:0,
       transition: {
         type: 'spring', duration:1, bounce: 0.3
       }
     })
    }
    if(!inView){
     animation.start({
       x: '-100vw'
     })
    }
 },[inView])

  return (
 

 
    <motion.div 
    ref={ref}
   
    initial={{opacity:0, translateX: -50,translateX: -50 }}
    animate={{opacity:1, translateX: 0, translateX: 0}}
    transition={{duration: 1.5, delay:  0.1}}
    className='portfolio-main-container'>

      <h1>Javascript Projects</h1>
    <div className='portfolio-container'>
   
      {datasource
         .filter(data => data.id < 27)
           .map((data)=>
      (
       
        <Link to={`/portfolio/${data.id}`} key={data.id}>
             <AnimationOnScroll animateIn="animate__bounceIn">
          <div className='portfolio-item'>
            <h3>{data.title}</h3>
            {data.websiteLink && <a href={data.websiteLink}>visit page</a>}
            <span></span>
          </div>
          </AnimationOnScroll>
        </Link>
        )
      )}
    </div>

    {/* react */}
    <h1>React Projects</h1>
    <div className='portfolio-container'>
   
      {datasource
       .filter(data => data.id >= 27 && data.id < 41)
      .map((data)=>(
        <Link to={`/portfolio/${data.id}`} key={data.id}>
            <AnimationOnScroll animateIn="animate__bounceIn">
          <div className='portfolio-item'>
            <h3>{data.title}</h3>
            {data.websiteLink && <a href={data.websiteLink}>visit page</a>}
            <span></span>
          </div>
          </AnimationOnScroll>
        </Link>
      ))}
    </div>
    {/* node */}
    <h1>MongoDB, Express, React and Node (MERN) Projects</h1>
    <div className='portfolio-container'>
   
      {datasource
      .filter(data => data.id > 41 )
      .map((data)=>( 
        <Link to={`/portfolio/${data.id}`} key={data.id}>
            <AnimationOnScroll animateIn="animate__bounceIn">
          <div className='portfolio-item'>
            <h3>{data.title}</h3>
            {data.websiteLink && <a href={data.websiteLink}>visit page</a>}
            <span></span>
          </div>
          </AnimationOnScroll>
        </Link>
       
    
      ))}
    </div>

    </motion.div>

    // </AnimationOnScroll>
  )
}

export default Portfolio
