import React, { useState } from 'react';
import instagram from '../socials/instagram.png'
import twitter from '../socials/twitter.png'
import linkedin from '../socials/linkedin.png'
import whatsapp from '../socials/whatsapp.png';
import email from '../socials/mail.png';
import './Contact.css';

// import tree from './img/9999.png';

const Contact = () => {
  const [isClick, setIsClick] = useState(false);
  const handleClick =()=>{
    setIsClick(true);
  }
  return (
    <>
    <div className='contact-container' style={{overflow: 'hidden'}}>
      <h2>Contact me on</h2>
       <div className="socials">
       <a href="https://www.linkedin.com/in/precious-dim-3b7679172">
          <img src={linkedin} alt="linkedin-profile" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=2348067919787" >
          <img src={whatsapp} alt="whatsapp-profile" />
          </a>
          
          <a href="https://www.instagram.com/precious_onthis">
          <img src={instagram} alt="instagram-profile" />
          </a>
          
          <a href='https://github.com/pressydon'>
          <img style={{objectFit:'cover'}} src='../img/github.png' alt="twitter-profile" />
          </a>
  
       </div>
      <div className='email'>

      <a href="mailto:contact@dimprecious.com">

   
          <div className='email-image'>
        <img src={email} alt='mail' />
        </div>
      
        <p className='email-title'>contact@dimprecious.com</p>

        </a>
            
        
        
      </div>
    </div>
{/* 
    {window.innerWidth > '1200px' && <div className={ isClick ? 'tree active' :'tree'}>
      <img src='./img/6666.png' alt='tree' />
    </div>} */}

    </>
  )
}

export default Contact
