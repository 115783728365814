/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import './HeroContainer.css';
import instagram from '../socials/instagram.png'
import twitter from '../socials/twitter.png'
import linkedin from '../socials/linkedin.png'
import whatsapp from '../socials/whatsapp.png'
import logo from '../img/logo.png';
import moon1 from '../img/m1.png';
import moon2 from '../img/m2.png';
import moon3 from '../img/m3.png';
import moon4 from '../img/m4.png';
import moon5 from '../img/m5.png';
import moon7 from '../img/m7.png';
import moon8 from '../img/m8.png';
import moon9 from '../img/m9.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';



const HeroContainer = () => {
  const imagesRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
 
  const sliderData = [
    {
      id: 1,
      src: moon1
    },
    {
      id: 2,
      src: moon2
    },
    {
      id: 3,
      src: moon3
    },
    {
      id: 4,
      src: moon4
    },
    {
      id: 5,
      src: moon5
    },
    {
      id: 6,
      src: moon7
    },
    {
      id: 7,
      src: moon8
    },
    {
      id: 8,
      src: moon9
    }
  
   
    
    
  ]
  const slideLength = sliderData.length;
  const autoScroll = true;
  var slideInterval;
  let intervalTime = 3950;

  // console.log(sliderData[0].src)

  const nextSlide = ()=>{
    setCurrentSlide(currentSlide === slideLength -1 ? 0 : currentSlide + 1);
    console.log(currentSlide + 1);
  }

  function auto(){
    slideInterval = setInterval(nextSlide, intervalTime)
  }

  useEffect(()=>{
    if(autoScroll){
     auto()
    }

    return ()=> clearInterval(slideInterval)

  },[currentSlide])

 

  return (
    <div style={{overflowX:'hidden'}} className='home-container'  >
       <div className="web-body">
        <div className="body-left">
          <span className='star'></span>
      <div className="inner-body">
            <h2>Hi, I'm</h2> <br/>
            <img src={logo} alt="logo" />
        </div>
       <AnimationOnScroll initiallyVisible={true} delay={2000} animateIn="animate__wobble">
   <h1>A Fullstack Developer</h1>
   </AnimationOnScroll>
   <div className="web-socials">
   
    
       <a href="https://www.instagram.com/precious_onthis">
       <img src={instagram} alt="instagram-profile" />
       </a>
    
       <a href='https://github.com/pressydon'>
       <img style={{objectFit:'cover'}} src='../img/github.png' alt="twitter-profile" />
       </a>
        <a href="https://www.linkedin.com/in/precious-dim-3b7679172">
        <img src={linkedin} alt="linkedin-profile" />
        </a>
        <a href="https://api.whatsapp.com/send?phone=2348067919787" >
        <img src={whatsapp} alt="whatsapp-profile" />
        </a>
      
   </div>
    
</div>

{/* <img ref={imagesRef} src={moon5} alt="homepage" /> */}


{sliderData.map((slide,index)=>(
  
    <div className="body-right" key={slide.id} >
      {index === currentSlide && (
         <img ref={imagesRef} src={slide.src} alt="homepage" />
      )}
    
   </div>
  ))


}
 
    

</div>
    </div>
  )
}

export default HeroContainer