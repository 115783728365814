import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './About.css';


const About = () => {

  const download = () => {
    var element = document.createElement("a");
    var file = new Blob(
      [
        "https://res.cloudinary.com/dyp6l6sj3/image/upload/v1664533035/resume_nz0tr2.png"
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "resume.png";
    // element.click();
  };

  // const downloadImage = () => {
  //   saveAs('image_url', './img/resume.png') // Put your image url here.
  // }

  return (


    <div className='about-container'>
      <div className='about-left'>

        <div className='about-left-top'>
        <AnimationOnScroll animateIn="animate__shakeY" animateOut="animate__bounceOutRight">
        <p>I  am a professional creative photographer and a Full Stack Software and Web Developer. My passion for solving complex creative problems led me to programming, beginning as a Frontend Developer and evolving into a Full Stack Developer. This journey, transitioning from design to frontend and now backend development, has honed my exceptional design skills and sharpened my ability to tackle intricate challenges, making me a more proficient developer. I have developed numerous software applications for both individuals and corporate organizations, demonstrating my expertise and versatility in the field. I am currently seeking milestone opportunities to further enhance my skills and advance my career as a Full Stack Developer. My technical proficiencies include JavaScript, React, React Native, Next.js, Node.js, Java, and Spring Boot.</p>
        </AnimationOnScroll>
        </div>
       
        <div className='about-left-bottom'>
        <div className='skill'>
          <img src='../img/js.png' alt='javascript' />
          <p>Very comfortable building and working with Javascript, css and sass</p>
        </div>
        <div className='skill'>
          <img src='../img/react.png' alt='react' />
          <p>React is lovely,I have professional experience working with react and so many frameworks like Commercejs,Sanity.io etc.</p>
        </div>
        <div className='skill'>
          <img src='../img/mongo.png' alt='mongoDB' />
          <p>For backend i am currently proficient working with Nodejs, express and MongoDB</p>
        </div>

        <div className='skill'>
          <img src='../img/java.png' alt='mongoDB' />
          <p>For backend currently learning and working with Java, MySql and Postgres</p>
        </div>

        <div className='skill'>
          <img src='../img/pringboot.png' alt='mongoDB' />
          <p>For backend currently learning and working with Spring Boot, MySql and Postgres</p>
        </div>
        <div className='skill'>
          <img src='../img/photoshop.png' alt='javascript' />
          <p>Can easily create and design with Photoshop</p>
        </div>

        <div className='skill'>
          <img src='../img/react.png' alt='javascript' />
          <p>I am very proficient building with React Native CLI and Expo for Io\OS and Android</p>
        </div>


        </div>
       
      </div>
      <div className='about-right'>

        <img src='../img/profile.jpg' alt='profile' />
        <p>Dim Precious C.</p>
        {/* <a
        // href='https://drive.google.com/file/d/1BhBgI9ZLrzQT9woypAbLSmNTZykFJgyN/view?usp=sharing'
        href='https://drive.google.com/file/d/1qhFIpdcEdVH9jTaWgEzBRwQ_G7I4Cm09/view?usp=sharing'
        download
        target="_blank"
        rel="noopener noreferrer"
        onClick={download()}
        >
             <button>View Resume</button>
        </a> */}
     
        
     

      </div>
      
    </div>
  
  )
}

export default About
