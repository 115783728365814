
const datasource = [
    {
        id: 1,
        title: 'A Responsive Ecommerce website for fruits and vegetables',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/1a.png' ,
                '../img/portfolio/1b.png' ,
                '../img/portfolio/1c.png' ,
                '../img/portfolio/1d.png' ],
        gitHub:'https://github.com/pressydon/Ecommerce-template',
     
      
      },
      {
        id: 2,
        title: 'A Responsive Alarm Reminder',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/2a.png' ,
                 '../img/portfolio/2b.png' ],
        gitHub:'https://github.com/pressydon/An-Alarm-Clock',
       
      
      },
      {
        id: 3,
        title: 'A Responsive Chess Board',
        builtWith:'HTML and CSS',
        images:['../img/portfolio/3a.png' ,
               '../img/portfolio/3b.png' ],
        gitHub:'https://github.com/pressydon/A-Chess-Board',
      
      },
      {
        id: 4,
        title: 'A Responsive Cinema Booking App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/4a.png' ,'../img/portfolio/4b.png'] ,
        gitHub:'https://github.com/pressydon/cinema-booking-app',
      
      },
      {
        id: 5,
        title: 'An Animated Countdown Timer',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/5a.png' ,
               '../img/portfolio/5b.png' ],
        gitHub:'https://github.com/pressydon/Animated-countdown-timer',
      
      },
      {
        id: 6,
        title: 'A Responsive Expense Tracker App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/7a.png' ,
               '../img/portfolio/7b.png' ],
        gitHub:'https://github.com/pressydon/expense-tracker',
      
      },
      {
        id: 8,
        title: 'A Responsive Movie Template',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/9a.png' ,
               '../img/portfolio/9b.png' ,
               '../img/portfolio/9c.png'] ,
        gitHub:'https://github.com/pressydon/movie-website-template',
      
      },
      {
        id: 9,
        title: 'An Animated Music Player App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/10a.png' ,
                '../img/portfolio/10b.png'] ,
        gitHub:'https://github.com/pressydon/Animated-music-player-app',
      
      
      },
    
      {
        id: 11,
        title: 'A Responsive Photography Website',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/12a.png' ,
                 '../img/portfolio/12b.png' ,
                 '../img/portfolio/12c.png'] ,
        gitHub:'https://github.com/pressydon/A-Responsive-photography-website',
        websiteLink: 'https://preciousphotographs.com',
      
      },
      {
        id: 12,
        title: 'A Simple NoteTaker App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/13a.png' ,
               '../img/portfolio/13b.png' ],
        gitHub:'https://github.com/pressydon/a-simple-notetaker-app',
       
      
      },
      {
        id: 13,
        title: 'A Number Api App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/14a.png'] ,
        gitHub:'https://github.com/pressydon/number-api',
        websiteLink: 'https://precious-number-api.netlify.app/'
      
      },
      {
        id: 14,
        title: 'A Responsive Random Dad Joke App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/15a.png' ,
                '../img/portfolio/15b.png'] ,
        gitHub:'https://github.com/pressydon/A-random-dad-jokes',
        websiteLink: 'https://precious-random-dad-jokes.netlify.app/'
      
      },
      {
        id: 15,
        title: 'A Time Tracker Challenge from frontendmentors.io',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/24a.png', '../img/portfolio/24b.png'] ,
        gitHub:'https://github.com/pressydon/frontendmentor-time-tracker',
       
      
      
      },
      {
        id: 16,
        title: 'A Responsive REST Country App Challenge With Dark Mode switch from frontendmentors.io',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/17a.png' ,
               '../img/portfolio/17b.png' ,
               '../img/portfolio/17c.png'] ,
        gitHub:'https://github.com/pressydon/A-REST-country-App-built-with-REST-Api-for-frontendmentor.io-challenge',
       
      
      },
      {
        id: 17,
        title: 'A ScreenRecoder and Downloader App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/18a.png' ,
              '../img/portfolio/18b.png'] ,
        gitHub:'https://github.com/pressydon/screen-recoder-app',
      
      },
      {
        id: 18,
        title: 'A Snake Game App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/19a.png'] ,
        gitHub:'https://github.com/pressydon/A-snake-game-app',
     
      
      },
      {
        id: 19,
        title: 'A Text to Speech converter App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/20a.png' ,
                '../img/portfolio/20b.png' ],
        gitHub:'https://github.com/pressydon/text-to-speech-converter',
        websiteLink: 'https://precious-text-speech-converter.netlify.app/'
      
      
      },
      {
        id: 20,
        title: 'A Responsive Admin Dashboard',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/21a.png' ,
             '../img/portfolio/21b.png' ,
             '../img/portfolio/21c.png'] ,
        gitHub:'https://github.com/pressydon/A-responsive-dashboard-with-javascript',
        websiteLink: 'https://the-pwhub-first-javascript-dashboard.netlify.app/'
      
      
      },
      {
        id: 21,
        title: 'A Theme And Font Switcher App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/23a.png' ,
                '../img/portfolio/23b.png' ,
                '../img/portfolio/23c.png' ,
                '../img/portfolio/22a.png' ,
                 '../img/portfolio/22b.png' ,
                  '../img/portfolio/23c.png'] ,
        gitHub:'https://github.com/pressydon/a-theme-switcher-app',
      
      },
      {
        id: 22,
        title: 'A Responsive Bill Splitter App challenge from frontendmentors.io',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/25a.png' ,
                '../img/portfolio/25b.png' ,
               '../img/portfolio/25c.png'] ,
        gitHub:'https://github.com/pressydon/github.io',
        websiteLink: 'https://precious-bill-splitter-app.netlify.app/'
      
      },
      {
        id: 23,
        title: 'A Responsive Youtube Clone ',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/27a.png'] ,
        gitHub:'https://github.com/pressydon/youtube-clone',
      
      },
      {
        id: 24,
        title: 'A Vowel Checker App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/28a.png' ,
               '../img/portfolio/28b.png'] ,
        gitHub:'https://github.com/pressydon/vowel-checker',
      
      },
      {
        id: 25,
        title: 'A Responsive Weather App',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/32a.png' ,
                '../img/portfolio/32b.png'] ,
        gitHub:'https://github.com/pressydon/weather-app-with-javascript',
      
      },
      {
        id: 26,
        title: 'A Responsive Price Column Template',
        builtWith:'HTML, CSS and Javascript',
        images:['../img/portfolio/30a.png' ,
               '../img/portfolio/30b.png'] ,
        gitHub:'https://github.com/pressydon/price-colum',
      
      },
      // react projects
      {
        id: 27,
        title: 'A Simple Calculator',
        builtWith:'React',
        images:['../img/portfolio/33a.png'] ,
        gitHub:'https://github.com/pressydon/calculator',
       
      
      },
      {
        id: 28,
        title: 'A Responsive Dashboard With Various Charts And Theme Switcher',
        builtWith:'React and Syncfusion',
        images:[
                 '../img/portfolio/34b.png' ,
                 '../img/portfolio/34c.png' ,
                 '../img/portfolio/34a.png' ,
                 '../img/portfolio/34d.png' ,
                 '../img/portfolio/34e.png' ,
                '../img/portfolio/34f.png' ],
        gitHub:'https://github.com/pressydon/Dashboard-with-react-and-syncfusion',
        websiteLink: 'https://admin-dashboard-with-syncfusion.netlify.app/'
      
      },
      {
        id: 29,
        title: 'A Responsive Ecommerce website',
        builtWith:'React and commercejs',
        images:['../img/portfolio/35a.png' ,
       '../img/portfolio/35b.png' ,
        '../img/portfolio/35c.png' ,
        '../img/portfolio/35d.png' ,
        '../img/portfolio/35e.png'] ,
        gitHub:'https://github.com/pressydon/ecommerce-website-made-with-react',
       
      
      },
      {
        id: 30,
        title: 'A Responsive Expense Tracker App',
        builtWith:'React',
        images:['../img/portfolio/36a.png' ,
               '../img/portfolio/36b.png' ],
        gitHub:'https://github.com/pressydon/React-expense-tracker',
       
      
      },
      {
        id: 31,
        title: 'A Responsive Website',
        builtWith:'React',
        images:['../img/portfolio/37a.png' ,
                '../img/portfolio/37b.png' ,
                '../img/portfolio/37c.png' ,
                '../img/portfolio/37d.png'] ,
        gitHub:'https://github.com/pressydon/My-first-react-website',
        websiteLink: '',
      
      },
      {
        id: 32,
        title: 'A Responsive Food Recipe App using Api',
        builtWith:'React',
        images:['../img/portfolio/38a.png' ,
                 '../img/portfolio/38b.png' ,
                 '../img/portfolio/38c.png' ,
                   '../img/portfolio/38d.png' ,
                   '../img/portfolio/38e.png' ,
                 '../img/portfolio/38f.png' ],
        gitHub:'https://github.com/pressydon/food-recipe-app-using-Api',
        websiteLink: 'https://precious-food-recipe-app.netlify.app/'
      
      },
      {
        id: 33,
        title: 'Ultimate Form Challenge with Multiple Steps',
        builtWith:'React',
        images:['../img/portfolio/39a.png' ,
                 '../img/portfolio/39b.png' ,
                '../img/portfolio/39c.png' ,
                '../img/portfolio/39d.png'] ,
        gitHub:'https://github.com/pressydon/form-challenge',
      
      },
      {
        id: 34,
        title: 'A Responsive Law website',
        builtWith:'React',
        images:['../img/portfolio/40a.png' ,
            '../img/portfolio/40b.png' ,
             '../img/portfolio/40c.png' ,
             '../img/portfolio/40d.png' ],
        gitHub:'https://github.com/pressydon/lexfortis-website',
        websiteLink: 'https://lexfortisattorneys.com',
      
      },
      {
        id: 35,
        title: 'A Simple Admin Dashboard',
        builtWith:'React',
        images:['../img/portfolio/41a.png' ,
                '../img/portfolio/41b.png' ,
                '../img/portfolio/41c.png'] ,
        gitHub:'#',
      
      },
      {
        id: 36,
        title: 'A Simple Responsive Ecommerce Template',
        builtWith:'React',
        images:['../img/portfolio/42a.png'] ,
        gitHub:'https://github.com/pressydon/a-precious-admin-dashboard',
      
      },
      {
        id: 37,
        title: 'A Simple and Colourful To-do app',
        builtWith:'React',
        images:['../img/portfolio/43a.png' ,
                 '../img/portfolio/43b.png'] ,
        gitHub:'https://github.com/pressydon/a-colourful-todo-app',
      
      },
      {
        id: 38,
        title: 'A Responsive Vacation Booking Website',
        builtWith:'React',
        image1:['../img/portfolio/44a.png' ,
                '../img/portfolio/44b.png' ,
                 '../img/portfolio/44c.png' ,
                 '../img/portfolio/44d.png'] ,
        gitHub:'https://github.com/pressydon/vacation-booking-site',
        websiteLink: 'https://precious-vacation-booking-app.netlify.app/'
      
      },
      {
        id: 39,
        title: 'A Simple Weather App',
        builtWith:'React',
        images:['../img/portfolio/45a.png' ,
                 '../img/portfolio/45b.png' ],
        gitHub:'https://github.com/pressydon/weather-app-with-react',
      
      },
      {
        id: 40,
        title: 'A Picture Sharing App With Masonry Layout and Google Login',
        builtWith:'React and sanity for Backend ',
        images:['../img/portfolio/46a.png' ,
                 '../img/portfolio/46b.png' ,
                  '../img/portfolio/46c.png' ,
                  '../img/portfolio/46f.png',
               '../img/portfolio/46d.png' ,
               '../img/portfolio/46e.png' ,
                ],
        gitHub:'https://github.com/pressydon/blueshare-jsm',
        websiteLink: 'https://blueshare-precious.netlify.app/',
      
      },

      // MERN

      {
        id: 41,
        title: 'A Simple Chat App',
        builtWith:'React, NodeJs and Express',
        images:['../img/portfolio/47a.png' ,
             '../img/portfolio/47b.png'] ,
        gitHub:'https://github.com/pressydon/simple-chat-app/tree/main',
       
      
      },
      {
        id: 42,
        title: 'A Simple User Management System',
        builtWith:'MongoDb, Ejs, NodeJs, Express',
        images:['../img/portfolio/48a.png' ,
               '../img/portfolio/48b.png' ,
               '../img/portfolio/48c.png'] ,
        gitHub:'https://github.com/pressydon/a-simple-user-management-system',
       
      
      },
      {
        id: 43,
        title: 'A To-do App',
        builtWith:' MongoDB, Express, React, NodeJs ',
        images:['../img/portfolio/49a.png' ,
               '../img/portfolio/49b.png' ],
        gitHub:'https://github.com/pressydon/Mern-todo-app',
        
      
      },
      {
        id: 44,
        title: 'A Responsive Vacation Booking App With Admin dashboard',
        builtWith:' MongoDB, Express, React, NodeJs ',
        images:['../img/portfolio/44a.png' ,
               '../img/portfolio/41a.png' ],
        gitHub:'https://github.com/pressydon/MERN-booking-website',
      },
      
      
]

export default datasource;