import React, { useState } from 'react'
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import logo from '../img/logo.png';
import menu from '../socials/more.png';
import cancel from '../socials/cancelgold.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';




const Navbar = () => {
  const [homeMenu, setHomeMenu]= useState(true)
  // const [showMenu, setShowMenu]= useState(false)

  const handleClick = ()=> setHomeMenu(!homeMenu);
  const closeMenu =()=> setHomeMenu(true);
  return (
    <div style={{overflowX: 'hidden'}} className='nav-container'>
      <nav>
      <div className="logo">
          <NavLink to='/'>
             <img src={logo} alt="logo" />
          </NavLink>
        </div>
      
        <div className={homeMenu ? 'nav-right' : 'nav-right active'}>
          <NavLink to='/' onClick={closeMenu}>
            Home
          </NavLink>
          <NavLink to='/portfolio' onClick={closeMenu}>
            Portfolio
          </NavLink>
          <NavLink to='/about' onClick={closeMenu}>
            About
          </NavLink>
          <NavLink to='/contact' onClick={closeMenu}>
            Contact
          </NavLink>

        </div>
      
        
      { homeMenu ? <img className='menu-btn' src={menu} alt='menu' onClick={handleClick} /> :
        <img className='close-btn' src={cancel} alt='close menu' onClick={handleClick} />}


      </nav>
    </div>
  )
}

export default Navbar
